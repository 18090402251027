import onLoad from '../../../../static/js/onLoad';

let currentContainerId = '';

function getContainer() {
  return document.getElementById(currentContainerId);
}

function hideAll() {
  const toHideSlideOvers = document.querySelectorAll(
    '._slide-over-panel:not(._translate-x-full)'
  );
  toHideSlideOvers.forEach((slideOver) => {
    slideOver.classList.remove('_translate-x-0', '_drop-shadow-md');
    slideOver.classList.add('_translate-x-full');

    setTimeout(() => {
      const parent = slideOver.closest('[role="dialog"]');
      // To ensure the exit-transition finishes before hiding the menu,
      // this need to be coordinated with the `_duration-300` class
      // on the SlideOver() macro.
      if (parent) {
        parent.classList.add('_hidden');
      }
    }, 300);

    // Remove any hash in the URL when closing
    if (window.location.hash === `#${slideOver.id.replace('-container', '')}`) {
      window.history.pushState(null, '', window.location.pathname);
    }
  });

  // For detecting from other places when the slide-over is closed
  document.dispatchEvent(new Event('slideOverClosed'));
}

function correctImageAspectRatio(images) {
  // This is a function to add padding to vertical images so that they don't
  // take up the entire slide-over.

  if (!images) {
    return;
  }

  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    if (
      image.classList.contains('_object-cover') &&
      image.width / image.height < 1
    ) {
      // Add padding to the wrapping div
      image.closest('div').classList.add('_px-20');
    }
  }
}

function handleClickOutside(e) {
  const toastContainer = document.querySelector('#toasts-container');
  if (toastContainer && toastContainer.contains(e.target)) {
    return;
  }
  const container = getContainer();
  if (container && !container.contains(e.target)) {
    hideAll();
  }
}

function handleKeydown(keyPressEvent) {
  // Let users press escape to remove the dialog
  const ESC_KEY = 27;

  const container = getContainer();

  if (container && keyPressEvent.keyCode === ESC_KEY) {
    if (container.querySelector('sl-dropdown[open]')) {
      // Ignore ESC press if the slide-over have an open dropdown
      return;
    }
    hideAll();
  }
}

function show(this: HTMLElement, event: Event) {
  event.stopPropagation();

  currentContainerId = this.id;
  const slideOverParent = this.closest('[role="dialog"]');
  const isAlreadyVisible = this.classList.contains('_translate-x-0');

  if (slideOverParent) {
    const images = this.querySelectorAll('img');
    correctImageAspectRatio(images);

    if (!isAlreadyVisible) {
      hideAll();

      window.requestAnimationFrame(() => {
        slideOverParent.classList.remove('_hidden');
        window.requestAnimationFrame(() => {
          this.classList.remove('_translate-x-full');
          this.classList.add('_translate-x-0', '_drop-shadow-md');
        });
      });
    } else {
      hideAll();
    }
  }
}

function hide() {
  hideAll();
}

function createEventsForFragment(frag) {
  const triggerShowElements = frag.querySelectorAll(
    '._slide-over-show-trigger'
  );

  triggerShowElements.forEach((trigger) => {
    trigger.addEventListener('click', (event) => {
      const container = document.getElementById(`${trigger.id}-container`);
      if (container) {
        show.call(container, event);
      }
    });
  });

  const triggerHideElements = frag.querySelectorAll(
    '._slide-over-hide-trigger'
  );
  triggerHideElements.forEach((element: HTMLElement) => {
    element.addEventListener('click', hide);
  });
}

function handleNavigation() {
  const { hash } = window.location;
  if (!hash) {
    // If no hash and we have an open slide-over, close it
    if (getContainer()) {
      hideAll();
    }
    return;
  }

  // Remove the # from the hash
  const slideOverId = hash.substring(1);
  const container = document.getElementById(`${slideOverId}-container`);

  if (container) {
    // Use existing show function with a synthetic event
    show.call(container, new Event('navigation'));
  }
}

function createEvents() {
  createEventsForFragment(document);
  window.addEventListener('keydown', handleKeydown);
  window.addEventListener('click', handleClickOutside);
  window.addEventListener('hideAllSlideOvers', hideAll);

  window.addEventListener('hashchange', handleNavigation);
  window.addEventListener('popstate', handleNavigation);
}

onLoad(() => {
  createEvents();
  if (typeof htmx !== 'undefined') {
    htmx.onLoad((target) => {
      // Add events for any slideover elemnts in the htmx fragment
      createEventsForFragment(target);
    });
  }
  handleNavigation();
});
